const topModal = (() => {

  const pageTop = (document.getElementById("pageTop") != null);
  if (pageTop) {


    MicroModal.init({
      disableScroll: true,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
      onShow: modal => console.info(`${modal.id} is shown`), // [1]
      onClose: modal => console.info(`${modal.id} is hidden`), // [2]
    });

    MicroModal.show('modal-1');
  }


})()
export default topModal;